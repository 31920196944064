import React, { lazy, Suspense } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { CartProvider } from "./Components/CrackersWeb/Shop/CartContext";
import { FavoriteProvider } from "./Components/CrackersWeb/Shop/FavoriteContext";
import FireworksEffect from "./Components/CrackersWeb/Home/FireworksEffect";
import { AuthProvider } from "./Components/CrackersWeb/Home/AuthContext";
import ProtectedRoute from "./Components/CrackersWeb/Home/ProtectedRoute";

const Header = lazy(() => import("./Components/CrackersWeb/Home/Header"));
const Carousel = lazy(() => import("./Components/CrackersWeb/Home/Carousel"));
const CategoriesGrid = lazy(() =>
  import("./Components/CrackersWeb/Home/CategoriesGrid")
);
const CategoryHeader = lazy(() =>
  import("./Components/CrackersWeb/Home/CategoryHeader")
);
const ContactImage = lazy(() =>
  import("./Components/CrackersWeb/Home/ContactImage")
);
const ShopPage = lazy(() => import("./Components/CrackersWeb/Shop/ShopPage"));
const QuickBuy = lazy(() =>
  import("./Components/CrackersWeb/QuickBuy/QuickBuy")
);
const AdminPage = lazy(() => import("./Components/AdminPage"));
const About = lazy(() => import("./Components/CrackersWeb/About/About"));
const Contact = lazy(() => import("./Components/CrackersWeb/Contact/Contact"));
const CartPage = lazy(() => import("./Components/CrackersWeb/Home/CartPage"));
const DeliveryDetails = lazy(() =>
  import("./Components/CrackersWeb/Home/DeliveryDetails")
);
const ProductDetail = lazy(() =>
  import("./Components/CrackersWeb/Shop/ProductDetail")
);
const ActPopUp = lazy(() => import("./Components/CrackersWeb/Home/ActPopUp"));
const Carousel2 = lazy(() => import("./Components/CrackersWeb/Home/Carousel2"));
const Icons = lazy(() => import("./Components/CrackersWeb/Home/Icons"));
const FavoritesPage = lazy(() =>
  import("./Components/CrackersWeb/Home/FavoritesPage")
);
const AdminLogin = lazy(() => import("./Components/CrackersWeb/Home/AdminLogin"));

function App() {
  const location = useLocation();

  return (
    <CartProvider>
      <FavoriteProvider>
        <AuthProvider>
          {!location.pathname.startsWith("/admin") && <ActPopUp />}
          <div className="App">
          <Suspense fallback={<div>Loading...</div>}>
          <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Header /> <FireworksEffect /> <Icons /> <Carousel />{" "}
                      <CategoryHeader /> <CategoriesGrid /> <Carousel2 />{" "}
                      <ContactImage />
                    </>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <>
                      <Header /> <FireworksEffect /> <Icons /> <Carousel />{" "}
                      <CategoryHeader /> <CategoriesGrid /> <Carousel2 />{" "}
                      <ContactImage />
                    </>
                  }
                />
                <Route
                  path="/shop"
                  element={
                    <>
                      <Header /> <ShopPage />
                    </>
                  }
                />
                <Route
                  path="/quickBuy"
                  element={
                    <>
                      <Header />
                      <QuickBuy />
                    </>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <>
                      <Header />
                      <About />
                    </>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <>
                      <Header />
                      <Contact />
                    </>
                  }
                />
                <Route
                  path="/cart"
                  element={
                    <>
                      <Header />
                      <CartPage />
                    </>
                  }
                />
                <Route
                  path="/delivery-details"
                  element={
                    <>
                      <Header />
                      <DeliveryDetails />
                    </>
                  }
                />
                <Route
                  path="/product/:id"
                  element={
                    <>
                      <Header /> <ProductDetail />
                    </>
                  }
                />
                <Route
                  path="/favorites"
                  element={
                    <>
                      <Header /> <FavoritesPage />
                    </>
                  }
                />
                <Route path="/adminLogin" element={<AdminLogin />} /> 
                <Route
                  path="/admin/*"
                  element={<ProtectedRoute element={<AdminPage />} />}
                />
              </Routes>
            </Suspense>
          </div>
        </AuthProvider>
      </FavoriteProvider>
    </CartProvider>
  );
}

export default App;
