import React, { createContext, useState } from 'react';

export const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);

  // Add or remove favorite items
  const addFavorite = (product) => {
    setFavorites((prevFavorites) => {
      if (prevFavorites.find((item) => item.id === product.id)) {
        // Remove from favorites if already present
        return prevFavorites.filter((item) => item.id !== product.id);
      }
      // Add to favorites if not present
      return [...prevFavorites, product];
    });
  };

  // Remove a favorite item
  const removeFavorite = (itemId) => {
    setFavorites((prevFavorites) => 
      prevFavorites.filter((item) => item.id !== itemId)
    );
  };

  return (
    <FavoriteContext.Provider value={{ favorites, addFavorite, removeFavorite }}>
      {children}
    </FavoriteContext.Provider>
  );
};
